import React from "react";
import Layout from "@components/layout";
import HeaderInner from "@components/header/header-inner";
import MobileNavInner from "@components/mobile-nav-inner";
import PageHeader from "@components/page-header";
import Footer from "@components/footer";
import TermsInner from "../components/terms-inner";
const Terms = () => {
  return (
    <Layout pageTitle="Itinerate - Terms of Service">
      <HeaderInner />
      <PageHeader title="Terms of Service" crumbTitle="Terms of Service" />
      <TermsInner/>
      <Footer />
      <MobileNavInner />
    </Layout>
  );
};

export default Terms;
